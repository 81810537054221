import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { ClockBreak, TimesheetModel } from '@app/reducers/orm/timesheet/timesheet.model';
import { DatePipe } from '@app/shared/sb-lib/calendar/pipes/date.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MapMarkerOption, MapsModule } from '@sb/maps';
import { DIALOG_DATA, LegacyDialogModule } from '@sb/ui';

import { ClockMapItem, clockPointer } from './interfaces';
import { TimesheetClockInfoComponent } from './timesheet-clock-info.component';

@Component({
  selector: 'app-clock-map-dialog',
  templateUrl: './clock-map-dialog.component.html',
  standalone: true,
  imports: [IconComponent, MapsModule, TranslateModule, CommonModule, LegacyDialogModule, TimesheetClockInfoComponent],
})
export class ClockMapDialogComponent {
  public timesheet: TimesheetModel;

  public markerOptions: MapMarkerOption[] = [];

  public constructor(
    private readonly datePipe: DatePipe,
    private readonly translate: TranslateService,
    @Inject(DIALOG_DATA)
    private readonly data: { timesheet: TimesheetModel },
  ) {
    this.timesheet = this.data.timesheet;
    this.setMarkerOptions();
  }

  private setMarkerOptions() {
    this.markerOptions = this.getClockMapItems().map((location) => ({
      id: `${location.label}-${location.datetime}`,
      lat: location.lat,
      lng: location.lng,
      icon: location.clockIcon,
      radius: location.radius,
      info: `${location.label}: ${this.datePipe.transform(location.datetime, 'HH:mm:ss')}`,
    }));
  }

  private getClockMapItems(): ClockMapItem[] {
    const items: ClockMapItem[] = [];

    if (!this.timesheet.clock) {
      return items;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { clocked_in, clocked_in_latitude, clocked_in_longitude, clocked_in_accuracy } = this.timesheet;
    if (clocked_in && !!clocked_in_latitude && clocked_in_longitude) {
      items.push({
        label: this.translate.instant('Clock in'),
        clockIcon: clockPointer['IN'],
        datetime: clocked_in,
        lat: parseFloat(clocked_in_latitude),
        lng: parseFloat(clocked_in_longitude),
        radius: parseFloat(clocked_in_accuracy) || 0,
      });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { ClockBreak } = this.timesheet;
    if (ClockBreak?.length > 0) {
      ClockBreak.forEach((clockBreak: ClockBreak) => {
        if (clockBreak.clocked_in && !!clockBreak.clocked_in_latitude && clockBreak.clocked_in_longitude) {
          items.push({
            label: this.translate.instant('Clock in break'),
            clockIcon: clockPointer['IN_BREAK'],
            datetime: clockBreak.clocked_in,
            lat: parseFloat(clockBreak.clocked_in_latitude),
            lng: parseFloat(clockBreak.clocked_in_longitude),
            radius: parseFloat(clockBreak.clocked_in_accuracy) || 0,
          });
        }

        if (clockBreak.clocked_out && !!clockBreak.clocked_out_latitude && clockBreak.clocked_out_longitude) {
          items.push({
            label: this.translate.instant('clock out break'),
            clockIcon: clockPointer['OUT_BREAK'],
            datetime: clockBreak.clocked_out,
            lat: parseFloat(clockBreak.clocked_out_latitude),
            lng: parseFloat(clockBreak.clocked_out_longitude),
            radius: parseFloat(clockBreak.clocked_out_accuracy) || 0,
          });
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { clocked_out, clocked_out_latitude, clocked_out_longitude, clocked_out_accuracy } = this.timesheet;
    if (clocked_out && !!clocked_out_latitude && clocked_out_longitude) {
      items.push({
        label: this.translate.instant('Clock out'),
        clockIcon: clockPointer['OUT'],
        datetime: clocked_out,
        lat: parseFloat(clocked_out_latitude),
        lng: parseFloat(clocked_out_longitude),
        radius: parseFloat(clocked_out_accuracy) || 0,
      });
    }
    return items;
  }
}
