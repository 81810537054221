export enum ConflictType {
  TIMESHEET = 'timesheet',
  ABSENCE = 'absence',
}

export interface TimesheetConflictModel {
  type: ConflictType;
  id: string;
}

export interface TimesheetConflictResponse {
  timesheetId: string;
  conflicts: TimesheetConflictModel[];
}

export interface TimesheetConflictStoreModel {
  id: string; // Timesheet ID that has the conflict
  conflicts: TimesheetConflictModel[];
  timestamp?: number;
}

export interface TimesheetConflictCheckRequest {
  employeeId: string;
  startTime: string;
  endTime: string;
  date: string;
  excludeTimesheetId?: string;
}

export interface TimesheetConflictRequest {
  from: string;
  to: string;
  departmentId: string[];
}
