<sb-legacy-dialog-header>
  {{ 'Clock map' | translate }}
</sb-legacy-dialog-header>
<sb-legacy-dialog-content [hasSidebar]="true" [withPadding]="false">
  <sb-maps
    class="h-96"
    [latitude]="markerOptions[0]?.lat"
    [longitude]="markerOptions[0]?.lng"
    [markers]="markerOptions"
    [showMarkerInfo]="true"
    [fitMarkerBounds]="true"
    [defaultHeight]="false"
    [zoom]="markerOptions[0]?.lat ? 12 : 8"
  ></sb-maps>
  <sb-legacy-dialog-sidebar>
    <!-- TODO replace with sb-legacy-dialog-block when we update timesheet-hours.component -->
    <timesheet-clock-info
      *ngIf="timesheet?.clock"
      class="modal-sidebar__block"
      [timesheet]="timesheet"
    ></timesheet-clock-info>
  </sb-legacy-dialog-sidebar>
</sb-legacy-dialog-content>
