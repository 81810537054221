export interface ClockMapItem {
  label: string;
  clockIcon: string;
  datetime: string;
  lat: number;
  lng: number;
  radius: number;
}

export const clockPointer = {
  IN: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
  OUT: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
  IN_BREAK: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  OUT_BREAK: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
};
